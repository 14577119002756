import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export const useGetProcessingTodos = () => {
  return {
    ...useQuery({
      queryKey: ["tasks_progress", "get"],
      queryFn: async () => {
        const result = await instance.get(`/tasks/progress`).catch((err) => {
          console.log(err, "ERROR");
        });
        return result;
      },
    }),
  };
};
