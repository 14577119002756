import React, { useEffect, useState } from "react";
import axios from "axios";

function GenerateImageForm({ todos, setTodos }) {
  const [prompt, setPrompt] = useState("");
  const [width, setWidth] = useState(512);
  const [height, setHeight] = useState(512);
  const [nftName, setNftName] = useState("");
  const [nftSymbol, setNftSymbol] = useState("");
  const [nftTypes, setNftTypes] = useState([]);
  const [selectedNftTypeInfo, setSelectedNftTypeInfo] = useState("");

  useEffect(() => {
    fetchNftTypes();
  }, []);

  const fetchNftTypes = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/nft-types"
      );
      setNftTypes(response.data);
    } catch (error) {
      console.error("Error fetching NFT types:", error);
    }
  };
  const handleWidthChange = (event) => {
    setWidth(event.target.value);
    console.log("Width selected:", event.target.value);
  };

  const handleHeightChange = (event) => {
    setHeight(event.target.value);
    console.log("Height selected:", event.target.value);
  };

  const addTodo = async () => {
    if (
      nftName &&
      nftSymbol &&
      prompt &&
      width &&
      height &&
      selectedNftTypeInfo &&
      selectedNftTypeInfo !== ""
    ) {
      try {
        const newTodo = {
          nftName,
          nftSymbol,
          prompt,
          width,
          height,
          nftCount: selectedNftTypeInfo.split("##")[0],
          price: selectedNftTypeInfo.split("##")[1],
        };
        const res = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/todos",
          newTodo
        );
        if (res && res.data) {
          console.log("newId:", res.data);
          setTodos([...todos, res.data]);
          setPrompt("");
          setWidth(512);
          setHeight(512);
          setSelectedNftTypeInfo(null);
          setNftName("");
          setNftSymbol("");
        }
      } catch (error) {
        console.error("Error adding todo:", error);
      }
    }
  };
  return (
    <div className="w-1/4 bg-gray-200 p-6 h-full">
      <h2 className="text-2xl font-bold mb-4 text-center">New Image</h2>
      <div className="space-y-2">
        <textarea
          className="w-full p-2 border rounded resize-none h-40"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Prompt words"
        />
        {/* Update the Width Field */}
        <div>
          <label htmlFor="nftName">NFT Name</label>
          <input
            id="nftName"
            name="nftName"
            className="w-full p-2 border rounded"
            value={nftName}
            onChange={(e) => setNftName(e.target.value)}
            placeholder="NFT Name"
          />
        </div>
        <div>
          <label htmlFor="nftSymbol">NFT Symbol</label>
          <input
            id="nftSymbol"
            name="nftSymbol"
            className="w-full p-2 border rounded"
            value={nftSymbol}
            onChange={(e) => setNftSymbol(e.target.value)}
            placeholder="NFT Symbol"
          />
        </div>
        <div>
          <label htmlFor="width">Width</label>
          <select
            className="w-full p-2 border rounded"
            id="width"
            name="width"
            value={width}
            onChange={handleWidthChange}
          >
            <option value="512">512</option>
            <option value="1024">1024</option>
            <option value="2048">2048</option>
          </select>
        </div>

        <div>
          <label htmlFor="height">Height</label>
          <select
            className="w-full p-2 border rounded"
            id="height"
            name="height"
            value={height}
            onChange={handleHeightChange}
          >
            <option value="512">512</option>
            <option value="1024">1024</option>
            <option value="2048">2048</option>
          </select>
        </div>
        <div>
          <label htmlFor="nftType">NFT Type</label>
          <select
            id="nftType"
            name="nftType"
            value={selectedNftTypeInfo}
            onChange={(e) => setSelectedNftTypeInfo(e.target.value)}
            className="border p-2 mr-2 rounded w-full"
          >
            <option value="">Select NFT Type</option>
            {nftTypes.map((nftType) => (
              <option
                key={nftType._id}
                value={`${nftType.nftCount}##${nftType.price}`}
              >
                {nftType.nftCount} NFTs - {nftType.price}ETH
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={addTodo}
          className="bg-blue-500 text-white w-full p-2 rounded hover:bg-blue-700"
        >
          Add Task
        </button>
      </div>
    </div>
  );
}

export default GenerateImageForm;
