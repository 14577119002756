import React from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ErrorIcon from "@mui/icons-material/Error";
import { PROGRESS_STATUS } from "../config/constants";

// List of steps
const steps = PROGRESS_STATUS;

const ProgressSteps = ({
  activeStep,
  progress,
  isFailure = false,
  isPaused = false,
  isRunning = false,
}) => {
  const renderStepIcon = (step) => {
    if (
      step === 0 || //pending
      (step === activeStep && step === PROGRESS_STATUS.length - 1) || // completed
      step < activeStep
    ) {
      // Display check icon if the step is completed
      return <CheckCircleIcon color="success" />;
    } else if (step === activeStep) {
      if (isFailure) {
        return <ErrorIcon color="error" />;
      } else {
        // Display spinner with progress percentage for the active step
        return (
          <Box display="flex" alignItems="center" gap={1}>
            {isPaused || !isRunning ? (
              <CircularProgress
                size={24}
                variant="determinate"
                value={progress}
              />
            ) : (
              <CircularProgress size={24} />
            )}
            <Typography variant="caption">{`${progress}%`}</Typography>
          </Box>
        );
      }
    } else {
      // Display inactive icon for pending steps
      return <RadioButtonUncheckedIcon color="disabled" />;
    }
  };

  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={null}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel icon={renderStepIcon(index)}>
              <Typography variant="caption">{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProgressSteps;
