import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDownloadExcel } from "react-export-table-to-excel";

const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getSeconds()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  } else {
    return "";
  }
};

const NFTTableWithLogs = () => {
  const [trackingData, setTrackingData] = useState([]);
  const [isTracking, setIsTracking] = useState(true);
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "tracking_data",
    sheet: "Sales",
  });

  const SearchTerm = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const filted_data = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/nft/tracking/search",
        {
          params: {
            keyword: e.target.value,
          },
        }
      );
      setTrackingData(filted_data.data);
    }
  };

  //==================================================================================================
  useEffect(() => {
    let interval;
    getDatabaseData();
    if (isTracking) {
      interval = setInterval(() => {
        startTracking();
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isTracking]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const startTracking = async (retries = 3) => {
    try {
      const url = process.env.REACT_APP_BACKEND_URL + "/nft/tracking";
      console.log("startTracking....");
      console.log(url);
      const response = await axios.get(url);
      setTrackingData(response.data);
    } catch (error) {
      if (retries > 0 && error.response.status === 429) {
        console.log("Rate limit exceeded. Retrying...");
        await delay(3000);
        return startTracking(retries - 1);
      }
      throw error;
    }
  };

  const getDatabaseData = async () => {
    try {
      const url = process.env.REACT_APP_BACKEND_URL + "/nft/tracking/db";
      const response = await axios.get(url);
      setTrackingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //==================================================================================================

  return (
    <div className="h-full p-6 overflow-x-auto">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold mb-4">NFT Sales Table</h1>
        <button
          className="bg-blue-500 text-white px-4 mb-2 rounded"
          onClick={onDownload}
        >
          Download xlsx
        </button>
        <input
          type="text"
          placeholder="Search NFTs"
          onKeyDown={(e) => SearchTerm(e)}
        />
        <p>Tracking sales...</p>
      </div>

      <div className="border rounded-lg shadow">
        <table
          className="min-w-full border border-gray-300 bg-white text-sm "
          ref={tableRef}
        >
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                #
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Deployed NFTs
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Collection name
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Text prompt
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                NFT token ID
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Deployment date and time
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Purchase Date
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Buyer address
              </th>
            </tr>
          </thead>
          <tbody>
            {trackingData.map((nft, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } hover:bg-gray-100`}
              >
                <td className="px-4 py-2 border-b">{index + 1}</td>
                <td className="px-4 py-2 border-b">{nft.nft_name}</td>
                <td className="px-4 py-2 border-b">{nft.collection}</td>
                <td className="px-4 py-2 border-b">
                  {nft.collection_generation}
                </td>
                <td className="px-4 py-2 border-b">{nft.nft_number}</td>
                <td className="px-4 py-2 border-b ">
                  {formatDate(nft.issue_date)}
                </td>
                <td className="px-4 py-2 border-b ">
                  {formatDate(nft.purchase_date)}
                </td>
                <td className="px-4 py-2 border-b ">{nft.buyer_address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NFTTableWithLogs;
