import React, { useState, useEffect } from "react";
import classNames from "classnames";

const EditItem = ({ todo, updateTodo, clickCancel }) => {
  const [prompt, setPrompt] = useState(todo.prompt);
  const [width, setWidth] = useState(todo.width);
  const [height, setHeight] = useState(todo.height);
  const [price, setPrice] = useState(todo.price);
  const [nftCount, setNftCount] = useState(todo.nftCount);

  const handleSave = () => {
    const updatedTodo = { ...todo, prompt, width, height, price, nftCount };
    updateTodo(updatedTodo);
  };

  return (
    <div className="flex-1 space-y-2">
      <textarea
        className="w-full p-1 border rounded resize-none h-20"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <div className="flex space-x-4">
        <input
          type="number"
          className="w-full p-1 border rounded"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
          placeholder="Width"
        />
        <input
          type="number"
          className="w-full p-1 border rounded"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          placeholder="Height"
        />
      </div>
      <input
        type="number"
        className="w-full p-1 border rounded"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        placeholder="Price"
      />
      <select
        className="w-full p-2 border rounded"
        value={nftCount}
        onChange={(e) => setNftCount(Number(e.target.value))}
      >
        <option value={2}>2 NFTs</option>
        <option value={5}>5 NFTs</option>
      </select>
      <div className="flex justify-end space-x-2">
        <button
          onClick={handleSave}
          className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-700"
        >
          Save
        </button>
        <button
          onClick={clickCancel}
          className="bg-gray-500 text-white px-2 py-1 rounded hover:bg-gray-700"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditItem;
